import React from 'react'
// import Image from 'gatsby-image'
import {Link} from 'gatsby'
import PortableText from './portableText'
import Container from './container'
import BuyButton from '../components/BuyButton'

// import styles from './page.module.css'
import styles from './product.module.css'

const Product = ({title, subTitle, body, _rawBody, price, productCategory, productOptions, mainImage, id, slug, taxable, weight, soldOut}) => {
  // console.log({pageSidebar})
  return (
    <>

      <article className={styles.root} >

        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
            <h4 className={styles.pagesubTitle}>{subTitle}</h4>
          </div>
          <div>
            <div className={styles.productContent}>

              <div className={styles.photosWrapper}>
                {_rawBody && <PortableText blocks={_rawBody} />}
              </div>
              <div className={styles.contentWrapper}>
                <p>{body}</p>
                {soldOut ? (
                  <p className={styles.soldOut}>SOLD OUT</p>
                ) : (
                  <BuyButton options={productOptions} price={price} title={title} image={mainImage} id={id} slug={slug} desc={body} taxable={taxable} weight={weight} />
                )}

              </div>

            </div>
          </div>
        </Container>
      </article>

    </>
  )
}
export default Product
