import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import Product from '../components/product'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      _id
      title
      subTitle
      slug {
        current
      }
      soldOut
      price
      productNumber
      taxable
      body
      _rawDescription
      productCategory
      productOptions {
        _key
        title
        price
      }
      productImage {
        asset {
          fluid(maxWidth: 300) {
            ...GatsbySanityImageFluid
          }
          url
        }
      }
      seoSettings {
      title
      description
      }
    }
  }
`

const ProductPage = ({data: {sanityProduct: page}}) => (
  <Layout title={page.title}>
    {page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} />
        )}
    <Product
      title={page.title}
      subTitle={page.subTitle}
      price={page.price}
      productCategory={page.productCategory}
      productOptions={page.productOptions}
      _rawBody={page._rawDescription}
      body={page.body}
      mainImage={page.productImage}
      id={page._id}
      slug={page.slug.current}
      taxable={page.taxable}
      weight={page.productNumber}
      soldOut={page?.soldOut}
    />
  </Layout>
)

export default ProductPage
