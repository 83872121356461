import React, {useState} from 'react'
import styles from './page.module.css'
// import Img from 'gatsby-image'

// options={productOptions} price={price} title={title} image={mainImage} id={id} slug={slug}

var BuyButton = React.memo(({options, price, title, image, id, slug, desc, taxable, weight}) => {
  // console.log({slug})
  // console.log({taxable})
  // const [selected, setSelected] = options
  //   ? useState(options[0])
  //   : useState({})
  const [selected, setSelected] = options
    ? useState(options[0])
    : useState('')
  const [totalPrice, setTotalPrice] = useState(price)
  const [productPrice, setProductPrice] = useState(price)
  const [isGift, setIsGift] = useState('No')
  const [giftTo, setGiftTo] = useState(null)
  const [giftFrom, setGiftFrom] = useState(null)

  const [qty, setQty] = useState(1)
  const optionList = (options) => {
    let list = ''
    // console.log({options})
    list = options.map((val) => {
      if (val.price !== null) {
        return val.title + '[+' + val.price + ']'
      } else {
        return val.title
      }
    })
    // console.log({list})
    return list.join('|')
  }

  const handleQtyChange = e => {
    const newQTY = parseInt(e.target.value)
    setQty(newQTY)
    updateTotalPrice(productPrice, newQTY)
  }

  const handleGiftChange = e => {
    // console.log(e.target.value)
    const newGift = e.target.value
    setIsGift(newGift)
  }
  const handleGiftFromChange = e => {
    // console.log(e.target.value)
    const giftFrom = e.target.value
    setGiftFrom(giftFrom)
  }
  const handleGiftToChange = e => {
    // console.log(e.target.value)
    const giftTo = e.target.value
    setGiftTo(giftTo)
  }

  const handleOptionChange = e => {
    const selectedPrice = e.target.selectedOptions[0].getAttribute('data-price')
    setSelected(e.target.value)
    var newProductPrice = price + (selectedPrice && parseFloat(e.target.selectedOptions[0].getAttribute('data-price')))
    setProductPrice(newProductPrice)

    updateTotalPrice(newProductPrice, qty)
  }
  const updateTotalPrice = (newProductPrice, newQTY) => {
    // console.log({newProductPrice})
    // console.log({newQTY})
    setTotalPrice(newProductPrice * newQTY)
  }
  const tax = taxable === null ? true : taxable

  // console.log(optionList(options))
  return (

    <div>

      {options.length ? (
        <>
          { options &&
            <select
              id={options.title}
              onChange={handleOptionChange}
              value={selected}
              className={styles.customSelect}
            >
              {options.map((x) => (<option data-price={x.price} key={x._key}>{x.title}</option>))}
            </select>
          }
        </>
      ) : ('')}
      <br />
      <input

        className={styles.customInput}
        type={'number'}
        name={'qty'}
        onChange={handleQtyChange}
        id={'qty'}
        value={qty}
        min={1}
        required
      />
      <br />
      <div className='form-group'>
        <label htmlFor='selectGift' >Is this a gift?</label>
        <select name='selectGift' onChange={handleGiftChange} className={styles.customSelect}>
          <option value='No'>No</option>
          <option value='Yes'>Yes</option>
        </select>
      </div>

      <br />

      {isGift === 'Yes' && (
        <>
          <div className='form-group'>
            <label htmlFor='giftFrom' >Who is this gift from?</label>
            <input
              className={styles.customInput}
              type={'text'}
              name={'giftFrom'}
              onChange={handleGiftFromChange}
              id={'to'}
            />
          </div>
          <br />
          <div className='form-group'>
            <label htmlFor='giftTo' >Who is this gift to?</label>
            <input
              className={styles.customInput}
              type={'text'}
              name={'giftTo'}
              onChange={handleGiftToChange}
              id={'to'}
            />
          </div>
          <br />
        </>
      )}

      <div> <p className={styles.productPrice}>${totalPrice.toFixed(2)}</p> </div>

      {options.length ? (
        <button
          data-item-custom1-name={'Options'}
          data-item-custom1-options={optionList(options)}
          data-item-custom1-value={selected}
          data-item-custom2-name='Is this a gift?'
          data-item-custom2-options='No|Yes'
          data-item-custom2-value={isGift}
          data-item-custom3-name='Who is the gift from'
          data-item-custom3-value={giftFrom}
          data-item-custom4-name='Who is the gift to'
          data-item-custom4-value={giftTo}

          style={{
            backgroundColor: '#212121',
            borderRadius: '5px',
            color: '#F5F5F5',
            fontWeight: 'bold',
            paddingBottom: '15px',
            paddingTop: '15px',
            paddingRight: '35px',
            paddingLeft: '35px',
            fontSize: '24'
          }}
          id='buyButton'
          className='snipcart-add-item buyBtn'
          data-item-id={id}
          data-item-quantity={qty}
          data-item-price={price}
          data-item-weight={weight}
          data-item-taxable={tax}
          data-item-has-taxes-included={!tax}
          data-item-name={title}
          data-item-description={desc}
          data-item-image={image.asset.url}
          data-item-url={'https://screaminpeach.com/shop/' + slug}>
        Buy Now
        </button>
      ) : (
        <button
          style={{
            backgroundColor: '#212121',
            borderRadius: '5px',
            color: '#F5F5F5',
            fontWeight: 'bold',
            paddingBottom: '15px',
            paddingTop: '15px',
            paddingRight: '35px',
            paddingLeft: '35px',
            fontSize: '24'
          }}
          id='buyButton'
          className='snipcart-add-item buyBtn'
          data-item-custom1-name='Is this a gift?'
          data-item-custom1-options='No|Yes'
          data-item-custom1-value={isGift}
          data-item-custom2-name='Who is the gift from:'
          data-item-custom2-value={giftFrom}
          data-item-custom3-name='Who is the gift to:'
          data-item-custom3-value={giftTo}
          data-item-id={id}
          data-item-quantity={qty}
          data-item-price={price}
          data-item-weight={weight}
          data-item-taxable={tax}
          data-item-has-taxes-included={!tax}
          data-item-name={title}
          data-item-description={desc}
          data-item-image={image.asset.url}

          data-item-url={'https://screaminpeach.com/shop/' + slug}>
        Buy Now
        </button>
      )}

    </div>
  )
})

export default BuyButton
